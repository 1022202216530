

















import { Component, Vue } from 'vue-property-decorator';

import MainBanner from './component/main-banner.vue';
import MainLineIntroduce from './component/main-line-introduce.vue';
import MainProcessSteps from './component/main-process-steps.vue';
import MainServiceAdvantages from './component/main-service-advantages.vue';
import MainElegantDemeanour from './component/main-elegant-demeanour.vue';

@Component({
    name: 'Home',
    components: {
        MainBanner,
        MainLineIntroduce,
        MainProcessSteps,
        MainServiceAdvantages,
        MainElegantDemeanour
    }
})
export default class Home extends Vue {
}
