

















import { Component, Vue } from 'vue-property-decorator';
@Component({
    name: 'MainElegantDemeanour',
    components: {}
})
export default class MainElegantDemeanour extends Vue {
    public list: any = [
        { url: '/demeanour/01.png' },
        { url: '/demeanour/02.png' },
        { url: '/demeanour/03.png' },
        { url: '/demeanour/04.png' }
    ];

    public jump() {
        this.$router.push({
            name: 'EnterpriseStyle'
        });
    }
}
