















import { Component, Vue } from 'vue-property-decorator';

import { UserModule } from '@/store/modules/user';

@Component({
    name: 'MainSearch',
    components: {}
})
export default class MainSearch extends Vue {
    public courierNumber: any = '';

    public tabData: any = [
        { label: this.$t('home.search.lable1'), url: '' },
        { label: this.$t('home.search.lable2'), url: 'Standard' }
    ];

    public jump(item, index) {
        if (index === 0 && UserModule.token === '') {
            this.$router.push({
                path: '/login/signin'
            });
            return;
        }
        if (item.url === '') {
            return;
        }
        this.$router.push({
            name: item.url
        });
    }

    public open(url) {
        window.open(url);
    }

    public query() {
        if (this.courierNumber === '') {
            const message: any = this.$t('public.placeholder.placeholder1');
            this.$message(message);
            return;
        }

        this.$router.push({
            name: 'StandardOrderQuery',
            query: {
                id: this.courierNumber
            }
        });
    }
}
