

























import { Component, Vue } from 'vue-property-decorator';

import { appConfigModule } from '@/store/modules/appConfig';

// 幻灯片切换时间 毫秒
const time: number = 2500;

// 定时器
let setInt: any = null;

@Component({
    name: 'MainProcessSteps',
    components: {}
})
export default class MainProcessSteps extends Vue {

    public stepActive: number = 0;

    public setpData: any = [
        { icon: 'icon-jiyunliucheng1 iconfont' },
        { icon: 'icon-zongbudizhi iconfont' },
        { icon: 'icon-jiyunliucheng3 iconfont' },
        { icon: 'icon-jiyunliucheng4 iconfont' },
        { icon: 'icon-jiyunliucheng5 iconfont' }
    ];

    public bannerData: any = [
        { src: this.appConfig.vueSrc + '/process/process01.png' },
        { src: this.appConfig.vueSrc + '/process/process02.png' },
        { src: this.appConfig.vueSrc + '/process/process03.png' },
        { src: this.appConfig.vueSrc + '/process/process04.png' },
        { src: this.appConfig.vueSrc + '/process/process05.png' }
    ];

    private mounted() {
        this.init();
    }

    private init() {
        this.mouseenter();
        setInt = setInterval(() => {
            const cunt = this.bannerData.length - 1;
            if (this.stepActive < cunt) {
                this.stepActive++;
            } else {
                this.stepActive = 0;
            }
        }, time);
    }

    // 鼠标移入
    public mouseenter() {
        clearInterval(setInt);
    }

    // 鼠标移出
    public mouseleave() {
        this.init();
    }

    public iconChange(index) {
        this.stepActive = index;
    }

    // 当前幻灯片样式
    public get styleObjet() {
        return (index) => {
            const is = this.stepActive === index;
            let obj = {
                zIndex: -(index)
            };
            if (is) {
                obj = {
                    zIndex: index + 1
                };
            }
            return obj;
        };
    }

    // 步骤条样式
    public get stepStyleObject() {
        return (index) => {
            this.$nextTick(() => {
                const is = this.stepActive === index;
                if (is) {
                    const canvas: any = document.querySelector(`#cvs${index}`).parentNode;
                    canvas.className = 'el-step__icon is-text show';
                } else {
                    const canvas: any = document.querySelector(`#cvs${index}`).parentNode;
                    canvas.className = 'el-step__icon is-text';
                }
            });
            return {};
        };
    }

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
