




























import { Component, Vue } from 'vue-property-decorator';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'MainServiceAdvantages',
    components: {}
})
export default class MainServiceAdvantages extends Vue {

    public list: any = [
        [
            { url: '/service/01.png', label: this.$t('home.service.lable1'), transform: '/service/transform/01.png' },
            { url: '/service/02.png', label: this.$t('home.service.lable2'), transform: '/service/transform/02.png' },
            { url: '/service/03.png', label: this.$t('home.service.lable3'), transform: '/service/transform/03.png' },
            { url: '/service/04.png', label: this.$t('home.service.lable9'), transform: '/service/transform/04.png' }
        ],
        [
            { url: '/service/05.png', label: this.$t('home.service.lable5'), transform: '/service/transform/05.png' },
            { url: '/service/06.png', label: this.$t('home.service.lable6'), transform: '/service/transform/06.png' },
            { url: '/service/07.png', label: this.$t('home.service.lable7'), transform: '/service/transform/07.png' },
            { url: '/service/08.png', label: this.$t('home.service.lable8'), transform: '/service/transform/08.png' }
        ]
    ];

    public jump() {
        this.$router.push({
            name: 'Introduce'
        });
    }

    public get Lable() {
        return (item) => {
            // 如果是第四张图片
            // if (item.url.indexOf('04') !== -1) {
            //     // 如果等于派将 或派将 辰风
            //     // eslint-disable-next-line eqeqeq
            //     if (this.shopId == '7' || this.shopId == '10' || this.shopId == '16') {
            //         // 专注集运
            //         return this.$t('home.service.lable9');
            //     }
            // }
            return item.label;
        };
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
