
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'MainLineIntroduce',
    components: {}
})
export default class MainLineIntroduce extends Vue {

    public activeIndex: number = 0;

    public contentData: any = [
        { src: '/route/01.png', src2: '/route/11.png' },
        { src: '/route/02.png', src2: '/route/22.png' },
        { src: '/route/03.png', src2: '/route/33.png' }
    ];

    // 设置下标
    public mouseenter(index) {
        this.activeIndex = index;
    }

    public jump() {
        this.$router.push({
            name: 'Route'
        });
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
