






























import { Component, Vue } from 'vue-property-decorator';
import MainSearch from './main-search.vue';

import { ArticleGetList } from '@/api/api';
import { searchConditionalTypeEnum, shopEnum } from '@/utils/type-enum';
import { otherModule } from '@/store/modules/other';
import { appConfigModule } from '@/store/modules/appConfig';

// 幻灯片切换时间 毫秒
const time: number = 2000;

// 定时器
let setInt: any = null;

@Component({
    name: 'MainBanner',
    components: {
        MainSearch
    }
})
export default class MainBanner extends Vue {
    public delay: Boolean = false;
    public height: number = 0;

    public vueShowId: any = this.appConfig.shopId

    public itemBg: string = this.appConfig.vueSrc + '/banner/banner-btn.png';
    public activeIndex: number = 0;
    public data: any = [];

    private mounted() {
        // 根据屏幕设置div高度
        this.height = window.innerHeight;
        this.init();
        setTimeout(() => {
            this.delay = true;
        }, 1000);

        // eslint-disable-next-line eqeqeq
        if (this.vueShowId == shopEnum['台集运']) {
            this.data = [
                { src: this.appConfig.vueSrc + '/banner/banner05.png', label: this.$t('home.banner.handleItem5'), zw: this.$t('home.banner.message5'), zw1: this.$t('home.banner.messagezw5'), yw: 'Buy with confidence' },
                { src: this.appConfig.vueSrc + '/banner/banner01.png', label: this.$t('home.banner.handleItem1'), zw: this.$t('home.banner.message1'), zw1: this.$t('home.banner.messagezw1'), yw: 'TAIWAN TRANSPORT' },
                { src: this.appConfig.vueSrc + '/banner/banner02.png', label: this.$t('home.banner.handleItem2'), zw: this.$t('home.banner.message2'), zw1: this.$t('home.banner.messagezw2'), yw: 'SMARTER LOGISTICS system' },
                { src: this.appConfig.vueSrc + '/banner/banner03.png', label: this.$t('home.banner.handleItem3'), zw: this.$t('home.banner.message3'), zw1: this.$t('home.banner.messagezw3'), yw: 'Safe  Efficient  Fast' },
                { src: this.appConfig.vueSrc + '/banner/banner04.png', label: this.$t('home.banner.handleItem4'), zw: this.$t('home.banner.message4'), zw1: this.$t('home.banner.messagezw4'), yw: 'Rest assured  Assure' }
            ];
        }
        // eslint-disable-next-line eqeqeq
        if (this.vueShowId != shopEnum['台集运']) {
            this.getBanner();
            this.data = [];
        }
    }

    private async getBanner() {
        try {
            // 这里的 FieldValue 值，根据总后台管理，写死
            const pageParams: any = {};
            pageParams.Expressionable = JSON.stringify([{ FieldName: 'CategoryId', FieldValue: 32, ConditionalType: searchConditionalTypeEnum['等于'] }]);
            const { DataList } = await ArticleGetList(pageParams);
            if (DataList && DataList.length) {
                DataList.forEach(item => {
                    this.data.push({
                        src: item.ImagePatch
                    });
                });
                otherModule.setHomeBanner(DataList);
            }
        } catch (error) {
            console.warn(error);
        }
    }

    private init() {
        this.mouseenter();
        setInt = setInterval(() => {
            const cunt = this.data.length - 1;
            if (this.activeIndex < cunt) {
                this.activeIndex++;
            } else {
                this.activeIndex = 0;
            }
        }, time);
    }

    // 鼠标移入
    public mouseenter() {
        clearInterval(setInt);
    }

    // 鼠标移出
    public mouseleave() {
        this.init();
    }

    // 设置幻灯片上下张显示
    public bannerHandle(index) {
        this.activeIndex = index;
    }

    // 动画显示类名
    public get className() {
        return (index) => {
            const className = this.activeIndex === index ? 'show' : '';
            return className;
        };
    }

    public get style() {
        return (index) => {
            return this.activeIndex === index ? { backgroundImage: 'url(' + this.itemBg + ')' } : {};
        };
    }

    public get dataActive() {
        return this.data[this.activeIndex];
    }

    // 主幻灯片 的样式
    // public get styleObject() {
    //     return (index: any) => {
    //         return {
    //             zIndex: index
    //         };
    //     };
    // }

    private get bannerArr() {
        return otherModule.homeBanner;
    }

    public get appConfig() {
        return appConfigModule.appConfig;
    }
}
